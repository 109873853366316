import React, { useRef } from "react";
import IMAGES from "../../img/image";
import Slider from "react-slick";
import "./HeroSection.css";

const HeroSection = () => {
  const arrowRef = useRef();

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="container-fluid hero-container">
        <Slider ref={arrowRef} {...settings}>
          <div className="p-3">
            <img src={IMAGES?.banner1} className="d-block w-100" alt="..." />
          </div>
          <div className="p-3">
            <img src={IMAGES?.banner2} className="d-block w-100" alt="..." />
          </div>
          <div className="p-3">
            <img src={IMAGES?.banner5} className="d-block w-100" alt="..." />
          </div>
          <div className="p-3">
            <img src={IMAGES?.banner7} className="d-block w-100" alt="..." />
          </div>
        </Slider>
      </div>
    </>
  );
};

export default HeroSection;
