const IMAGES = {
  //HOME
  banner1: require("../img/home/banner1.jpg"),
  banner2: require("../img/home/banner2.jpg"),
  banner3: require("../img/home/banner3.webp"),
  banner4: require("../img/home/banner4.jpeg"),
  banner5: require("../img/home/banner5.jpg"),
  banner6: require("../img/home/banner6.jpg"),
  banner7: require("../img/home/banner7.jpg"),
  safe: require("../img/products/safe.png"),
  // HOME
  trusted: require("../img/home/trusted.svg"),
  register: require("./home/register.svg"),
  verified: require("./home/verified.svg"),
  privacy: require("./home/privacy.svg"),
};

export default IMAGES;
