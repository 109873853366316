import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import { message } from "antd";
import Games from "../components/Games";
import "./ProductPage.css";

const ProductPage = () => {
  const [setProducts] = useState(null);

  async function getAllProducts() {
    try {
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <Layout>
      <div className="product-page-container">
        <Games />
      </div>
    </Layout>
  );
};

export default ProductPage;
