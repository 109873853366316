import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function PublicRoute({ children }) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  if (localStorage.getItem("token")) {
    navigate("/");
  } else {
    return children;
  }
}
